import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, TabContent, TabPane, Table } from "reactstrap";
import { getCreditsHistory } from "../../../../api/orders";
import styles from "./styles.module.css";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import CreditsListing from "./CreditsListing";
import DebitsListing from "./DebitsListing";
import Section from "./Section";

const Orders = () => {
  const [creditList, setCreditList] = useState([]);
  const [debitList, setdebitList] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const planDetails = useSelector((state) => state.employer.planDetails);

  useEffect(() => {
    const getHistory = async () => {
      try {
        const creditResponse = await getCreditsHistory("credit");
        const debitResponse = await getCreditsHistory("debit");

        setCreditList(creditResponse?.data);
        setdebitList(debitResponse?.data);
      } catch (err) {
        console.log("err::", err);
      }
    };

    getHistory();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const NavButton = ({ name, tabId, id }) => {
    return (
      <NavItem role='presentation'>
        <NavLink
          className={classnames({ active: activeTab === tabId })}
          onClick={() => {
            tabChange(tabId);
          }}
          id={id}
          type='button'
          role='tab'
        >
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <Section />

      <div className={styles.main_sec}>
        <Container>
          <Nav
            tabs
            className='job-list-menu  nav-pills nav-justified flex-column flex-sm-row mb-4'
            id='pills-tab'
            role='tablist'
          >
            <NavButton name='All' id='all-tab' tabId={"1"} />
            <NavButton name='Credit Usage History' id='debit-tab' tabId={"2"} />
            <NavButton name='Credit Buy History' id='credit-tab' tabId={"3"} />
          </Nav>

          <TabContent activeTab={activeTab} className={styles.tabContent}>
            <TabPane tabId='1'>
              {planDetails?.plan ? (
                <Table bordered responsive>
                  <thead style={{ background: "#f8f9fa" }}>
                    <tr>
                      <th>Total Credits Purchased</th>
                      <th>Total Credits Used</th>
                      <th>Credit Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{planDetails?.credits || 0}</td>
                      <td>{planDetails?.debits || 0}</td>
                      <td>{planDetails?.balance || 0}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">No history found.</p>
              )}
            </TabPane>

            <TabPane tabId='2'>
              <DebitsListing debitList={debitList} />
            </TabPane>

            <TabPane tabId='3'>
              <CreditsListing creditList={creditList} />
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  );
};

export default Orders;
