import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Spinner } from "reactstrap";
import { placeOrder } from "../../api/orders";
import { apiErrorHandler } from "../../helpers/common";

const AddressForm = ({ planId, setCheckoutRes }) => {
  const INITIAL_STATE = {
    name: "",
    city: "",
    pin_code: "",
    additional_mobile_number: "",
    address: "",
    country_code: "IN",
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});

  const onValueChange = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name) {
      setErrors({ name: "Please enter your name" });
      return;
    }

    if (!formData.city) {
      setErrors({ city: "Please enter your city" });
      return;
    }

    if (!formData.address) {
      setErrors({ address: "Please enter your full address" });
      return;
    }

    if (!formData.pin_code) {
      setErrors({ pin_code: "Please enter your pin code" });
      return;
    }

    setLoading(true);

    try {
      const payload = {
        plan_id: parseInt(planId),
        items: [],
        customer: formData,
      };
      const response = await placeOrder(payload);
      setCheckoutRes(response?.data);
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const ErrorMsg = ({ msg }) => {
    return <small className='text-danger text-small mt-5'>{msg}</small>;
  };

  return (
    <form onSubmit={onSubmit}>
      <div className='mb-3'>
        <label htmlFor='name' className='form-label'>
          Name *
        </label>
        <Input
          type='text'
          value={formData.name}
          className='form-control'
          id='name'
          placeholder='Enter your name'
          name='name'
          invalid={errors.name ? true : false}
          onChange={(e) => onValueChange(e, "name")}
        />

        <ErrorMsg msg={errors.name} />
      </div>

      <div className='mb-3'>
        <label htmlFor='additional_mobile_number' className='form-label'>
          Additional mobile number
        </label>
        <Input
          type='text'
          value={formData.additional_mobile_number}
          className='form-control'
          id='additional_mobile_number'
          placeholder='Enter your additional mobile number'
          name='additional_mobile_number'
          invalid={errors.additional_mobile_number ? true : false}
          onChange={(e) => onValueChange(e, "additional_mobile_number")}
        />
        <ErrorMsg msg={errors.additional_mobile_number} />
      </div>

      <div className='mb-3'>
        <label htmlFor='city' className='form-label'>
          Your City *
        </label>
        <Input
          type='text'
          value={formData.city}
          className='form-control'
          id='city'
          placeholder='Enter your city'
          name='city'
          invalid={errors.city ? true : false}
          onChange={(e) => onValueChange(e, "city")}
        />
        <ErrorMsg msg={errors.city} />
      </div>

      <div className='mb-3'>
        <label htmlFor='address' className='form-label'>
          Your Address *
        </label>
        <Input
          type='textarea'
          rows={3}
          value={formData.address}
          className='form-control'
          id='address'
          placeholder='Enter your address'
          name='address'
          invalid={errors.address ? true : false}
          onChange={(e) => onValueChange(e, "address")}
        />
        <ErrorMsg msg={errors.address} />
      </div>

      <div className='mb-3'>
        <label htmlFor='pincode' className='form-label'>
          Pincode *
        </label>
        <Input
          type='text'
          value={formData.pin_code}
          className='form-control'
          id='pincode'
          placeholder='Enter your 6 digit pincode'
          name='pin_code'
          invalid={errors.pin_code ? true : false}
          onChange={(e) => onValueChange(e, "pin_code")}
        />
        <ErrorMsg msg={errors.pin_code} />
      </div>

      {loading ? (
        <Link to='#' className='btn btn-primary disabled w-100'>
          Submit <Spinner size='sm' />
        </Link>
      ) : (
        <>
          <button className='btn btn-primary w-100' type='submit'>
            Submit <i className='mdi mdi-send'></i>
          </button>
        </>
      )}
    </form>
  );
};

export default AddressForm;
