import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classname from "classnames";
import Avatar from "../../../assets/images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/customer";
import EmployerUserDropdown from "./EmployerUserDropdown";
import CandidateUserDropdown from "./CandidateUserDropdown";
import WebsiteLogo from "../../../helpers/WebsiteLogo";
import {
  authKey_local_storage_key,
  dataUrl,
  getCreditText,
  isUserEmployer,
} from "../../../helpers/common";
import Notification from "../Notification";
import { Icon } from "@iconify/react";
import { GET_JOBS_APPLICATIONS_FAILURE } from "../../../constants/actionTypes";
// import Notification from "./Notification";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { profile } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorage.getItem(authKey_local_storage_key);

  const dispatch = useDispatch();

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  const profile_picture = additional_fields.profile_picture
    ? `${dataUrl}/customers/120x120-${additional_fields?.profile_picture}`
    : null;

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);

  const toggleUserDropdown = () => {
    if (authToken) {
      setUserProfile((prevState) => !prevState);
    }
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch({ type: GET_JOBS_APPLICATIONS_FAILURE });
    setUserProfile(false);
  };

  const goToSignin = () => {
    if (!authToken) {
      navigate("/signin");
    }
  };

  //scroll navbar
  const [navClass, setnavClass] = useState(false);
  const menus = useSelector((state) => state.menus.data);

  const menuCategory = isUserEmployer(profile)
    ? "top_employers_menus"
    : "top_menus";
  const topMenus = menus.filter((menu) => menu.category === menuCategory);
  const parentMenus = topMenus.filter((menu) => menu.parent_id === 0);

  const planDetails = useSelector((state) => state.employer.planDetails);

  const creditBalance = planDetails?.balance || 0;
  const isBalanceLow = creditBalance === 0

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  });

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //menu activation
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navbarCollapse");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  // Getting child menus from parent menu id
  const getChildMenus = (id) => {
    return topMenus.filter((menu) => menu.parent_id === id);
  };

  const scrollTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg fixed-top sticky p-0 " + navClass}
        id='navigation'
      >
        <Container fluid className='custom-container'>
          <Link className='navbar-brand text-dark fw-bold me-auto' to='/'>
            <WebsiteLogo height='35' />
          </Link>
          <div>
            <NavbarToggler
              className='me-3'
              type='button'
              onClick={() => toggle()}
            >
              <i className='mdi mdi-menu'></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className='navbar-collapse'
            id='navbarCollapse'
          >
            <ul className='navbar-nav mx-auto navbar-center'>
              {parentMenus?.map((menu) => (
                <NavItem key={menu.id} className='dropdown dropdown-hover'>
                  <Link
                    to={getChildMenus(menu.id)?.length > 0 ? "#" : menu.url}
                    id='jobsdropdown'
                    role='button'
                    className='nav-link'
                    onClick={scrollTop}
                  >
                    {menu.name}
                    {getChildMenus(menu.id)?.length > 0 && (
                      <div className='arrow-down'></div>
                    )}
                  </Link>

                  {getChildMenus(menu.id)?.length > 0 && (
                    <ul
                      className={classname(
                        "dropdown-menu dropdown-menu-center"
                      )}
                      aria-labelledby='jobsdropdown'
                    >
                      {getChildMenus(menu.id)?.map((childMenu) => (
                        <li key={childMenu.id}>
                          <Link
                            onClick={scrollTop}
                            className='dropdown-item'
                            to={childMenu.url}
                          >
                            {childMenu.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </NavItem>
              ))}
            </ul>
          </Collapse>

          <ul className='header-menu list-inline d-flex align-items-center mb-0'>
            <li> {profile?.id && <Notification />}</li>

            {isUserEmployer(profile) && (
              <li className='position-relative ms-1 me-3 h-100 d-none d-sm-block'>
                <Link className='primary-link fs-13 ' to={isBalanceLow ? '/pricing' : '/employer/dashboard'}>
                  <div className={`d-flex align-items-center wallet-icon ${isBalanceLow ? 'bg-danger' : ''} `}>
                    <i className='mdi mdi-wallet'></i>
                    <span className='ms-1'>
                      <span className="count">{creditBalance} <br /></span>
                      {getCreditText(creditBalance)} left
                    </span>
                  </div>
                </Link>
              </li>
            )}

            <Dropdown
              isOpen={userProfile}
              toggle={toggleUserDropdown}
              className='list-inline-item'
              onClick={goToSignin}
            >
              <DropdownToggle
                to='#'
                className='header-item'
                id='userdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
              >
                {profile?.name ? (
                  <img
                    src={
                      additional_fields?.profile_picture
                        ? profile_picture
                        : Avatar
                    }
                    alt='user-img'
                    width='40'
                    height='40'
                    className='rounded-circle me-2'
                    style={{ border: "1px solid #eee" }}
                  />
                ) : (
                  <Icon
                    icon={"material-symbols:login"}
                    color='#000'
                    style={{ marginRight: 5 }}
                    fontSize={20}
                  />
                )}

                <span className='d-none d-md-inline-block fw-medium'>
                  {profile?.name ? profile?.name : "Signin"}
                </span>
              </DropdownToggle>

              {profile && profile.customer_type === 1 ? (
                <EmployerUserDropdown
                  onLogout={onLogout}
                  toggleUserDropdown={toggleUserDropdown}
                />
              ) : (
                <CandidateUserDropdown
                  onLogout={onLogout}
                  toggleUserDropdown={toggleUserDropdown}
                />
              )}
            </Dropdown>
          </ul>
        </Container>
      </nav>
    </React.Fragment>
  );
};

// TODO:
// export default withRouter(NavBar);
export default NavBar;
