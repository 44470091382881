import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  downloadDocument as downloadDocumentApi,
  getCustomerFullDetails,
} from "../../api/customer";
import { apiErrorHandler, buyCreditsAlert, dataUrl, isInsufficientCreditError } from "../common";

const useDocumentDownloader = () => {
  const [loading, setLoading] = useState(false);
  const [downloadCustomerId, setDownloadCustomerId] = useState(null);
  const [documentType, setDocumentType] = useState("resume");
  const { pathname } = useLocation();

  const downloadDocument = async (customerId, field = "resume") => {
    if (loading) {
      return false;
    }

    setLoading(true);
    setDownloadCustomerId(customerId);
    setDocumentType(field);
    try {
      const response = await getCustomerFullDetails(customerId);
      const responseData = response?.data;

      if (responseData?.status === "error") {
        throw new Error(responseData?.message);
      }

      if (responseData?.token) {
        const res = await downloadDocumentApi(responseData?.token, field);

        const documentUrl = `${dataUrl}/customers/${res?.data}`;

        window.open(documentUrl, "_blank");
      }
    } catch (error) {
      if (isInsufficientCreditError(error?.message)) {
        buyCreditsAlert(error?.message, pathname);
      } else {
        apiErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return [downloadDocument, { loading, downloadCustomerId, documentType }];
};

export default useDocumentDownloader;
