import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import {
  getCustomerPublicDetails,
} from "../../api/customer";
import { scrollWindow } from "../../helpers/common";
import SmallLoader from "../../helpers/Common/SmallLoader";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
import Section from "./Section";

const CandidateProfileDetails = () => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { candidateId } = useParams();

  useEffect(() => {
    scrollWindow();

    getCustomerPublicDetails(candidateId)
      .then((res) => {
        setDetails(res?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
      .finally(() => setLoading(false));
  }, [candidateId]);

  const additional_fields = details?.additional_fields
    ? JSON.parse(details?.additional_fields)
    : {};

  return (
    <React.Fragment>
      <MetaTags>
        <title>My Profile | Jobseekers Page </title>
      </MetaTags>
      <Section />

      {loading ? (
        <SmallLoader />
      ) : (
        <section className='section'>
          <Container>
            <Row>
              <LeftSideContent
                additional_fields={additional_fields}
                details={details}
              />
              <RightSideContent additional_fields={additional_fields} />
            </Row>
          </Container>
        </section>
      )}
    </React.Fragment>
  );
};

export default CandidateProfileDetails;
