import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { authKey_local_storage_key, toArray } from "../../../helpers/common";
import SmallLoader from "../../../helpers/Common/SmallLoader";

const PricingPage = () => {
  const authToken = localStorage.getItem(authKey_local_storage_key);

  const orders = useSelector(state => state.orders);
  const plans = orders.plans;
  const loading = orders.plan_loading;

  const PlanListing = () => {
    if (loading) {
      return <SmallLoader />
    }

    return (
      <Row>
        {plans?.map((plan, key) => (
          <Col lg={4} md={6} className='mt-5 pt-2' key={key}>
            <Card className='pricing-box bg-light'>
              <CardBody className='p-4 px-lg-5'>
                <div className='pricing-icon bg-light rounded-circle icons-md'>
                  <Icon icon={"uim-telegram-alt"} className='text-primary' />
                </div>
                <div className='pricing-name text-center mt-4 pt-2'>
                  <h4 className='fs-18'>{plan.name}</h4>
                </div>
                <div className='pricing-price text-center mt-4'>
                  <h2 className='fw-semibold'>
                    ₹{parseInt(plan.plan_amount)}
                    <small className='fs-16'></small>
                  </h2>
                </div>
                <ul className='list-unstyled pricing-details text-muted mt-4'>
                  {toArray(plan?.description, "/n")?.map((desc, index) => (
                    <li key={index} className={"pricing-item d-flex"}>
                      <i
                        className={"mdi mdi-check-bold bg-soft-success me-2"}
                      ></i>{" "}
                      <span>{desc}</span>
                    </li>
                  ))}
                </ul>
                <div className='text-center mt-4 mb-2'>
                  <Link
                    to={authToken ? `/checkout/${plan?.id}` : "/signin"}
                    className={`btn btn-soft-primary rounded-pill`}
                  >
                    Purchase Now <i className='uil uil-arrow-right'></i>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <React.Fragment>
      <section className='section mt-md-5 mt-4'>
        <Container>
          <Row className='justify-content-center mt-md-5'>
            <Col lg={6}>
              <div className='text-center'>
                <span className='badge bg-soft-warning  fs-15 mb-2'>
                  Choose Your Plan
                </span>
                <p className='text-muted'>
                  Purchase credits to view candidates profiles
                </p>
              </div>
            </Col>
          </Row>

          <PlanListing />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
